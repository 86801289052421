import { useEffect } from "react";

const imgData = {
  imgs: [
    {
      imgset: [
        {
          id: "0-0",
          src: "images/gallery/img1/school-1.jpg",
          caption: "school",
        },
        {
          id: "0-1",
          src: "images/gallery/img1/shopping-3.jpg",
          caption: "shopping",
        },
        {
          id: "0-2",
          src: "images/gallery/img1/AL-12.jpg",
          caption: "food",
        },
        {
          id: "0-3",
          src: "images/gallery/img1/spccer-2-171341097.jpg",
          caption: "school",
        },
      ],
    },
    {
      imgset: [
        {
          id: "1-0",
          src: "images/gallery/img2/AL-4.jpg",
          caption: "school",
        },
        {
          id: "1-1",
          src: "images/gallery/img2/DSCF-1.jpg",
          caption: "shopping",
        },
        {
          id: "1-2",
          src: "images/gallery/img2/iStock-1.jpg",
          caption: "food",
        },
        {
          id: "1-3",
          src: "images/gallery/img2/AL-2.jpg",
          caption: "school",
        },
      ],
    },
    {
      imgset: [
        {
          id: "2-0",
          src: "images/gallery/img3/AL-5.jpg",
          caption: "school",
        },
        {
          id: "2-1",
          src: "images/gallery/img3/shopping-1.jpg",
          caption: "shopping",
        },
        {
          id: "2-2",
          src: "images/gallery/img3/AL-10.jpg",
          caption: "food",
        },
        {
          id: "2-3",
          src: "images/gallery/img3/golf-1-1371391249.jpg",
          caption: "school",
        },
      ],
    },
    {
      imgset: [
        {
          id: "3-0",
          src: "images/gallery/img4/AL-6.jpg",
          caption: "school",
        },
        {
          id: "3-1",
          src: "images/gallery/img4/AL-8.jpg",
          caption: "shopping",
        },
        {
          id: "3-2",
          src: "images/gallery/img4/food-1.jpg",
          caption: "food",
        },
        {
          id: "3-3",
          src: "images/gallery/img4/pilates-1364931616.jpg",
          caption: "school",
        },
      ],
    },
    {
      imgset: [
        {
          id: "4-0",
          src: "images/gallery/img5/AL-7.jpg",
          caption: "school",
        },
        {
          id: "4-1",
          src: "images/gallery/img5/shopping-2.jpg",
          caption: "shopping",
        },
        {
          id: "4-2",
          src: "images/gallery/img5/AL-11.jpg",
          caption: "food",
        },
        {
          id: "4-3",
          src: "images/gallery/img5/AL3A4477.jpg",
          caption: "school",
        },
      ],
    },
    {
      imgset: [
        {
          id: "5-0",
          src: "images/gallery/img6/WDSCF0523.jpg",
          caption: "school",
        },
        {
          id: "5-1",
          src: "images/gallery/img6/school-2.jpg",
          caption: "shopping",
        },
        {
          id: "5-2",
          src: "images/gallery/img6/AL-9.jpg",
          caption: "food",
        },
        {
          id: "5-3",
          src: "images/gallery/img6/davey-gravy-QnVBdXQabdw-unsplash-1.jpg",
          caption: "school",
        },
      ],
    },
  ],
};

function ImgCollage() {
  // contain all img elements
  let imgArr = [[], [], [], [], [], []];

  useEffect(() => {
    // give seven ways to display six images in seven rounds
    const imgCollageArray = [
      [1, 2, 1, 2],
      [1, 1, 2, 2],
      [2, 1, 1, 2],
      [1, 3, 2],
      [1, 2, 3],
      [2, 2, 2],
      [2, 1, 2, 1],
    ];
    // init count the running time for setinterval
    let n = 1;
    // total 4 group images for each postion
    let numImgs = 4;

    // main function to run image collage animation
    function imageCollage() {
      // loop seven ways to display six images in seven rounds
      for (let i = 0; i < imgCollageArray.length; i++) {
        // define the fixed index of imgset attribute
        let imgsetCurrindexs = [0, 1, 2, 3, 4, 5];

        // each ways: loop how many images need to be changed in i second
        for (let j = 0; j < imgCollageArray[i].length; j++) {
          // get activeImgsetObjs array
          let activeImgsetObjs = [];

          for (let k = 0; k < imgCollageArray[i][j]; k++) {
            let randomIndex = Math.floor(
              Math.random() * imgsetCurrindexs.length
            );
            let randomIndexValue = imgsetCurrindexs[randomIndex];

            let activeImgset = document.querySelector(
              "[curr_index=" + CSS.escape(randomIndexValue) + "]"
            );
            imgsetCurrindexs.splice(randomIndex, 1);

            let activeimgsetObj = {
              currindex: randomIndexValue,
              activeImgset: activeImgset,
            };
            activeImgsetObjs.push(activeimgsetObj);
          }

          // increase second in every img changing
          n++;
          // when the first "seven rounds" ends, the starting time reset to 1
          if (n === 26) {
            n = 1;
          }

          // active imgs
          activeImgsetObjs.forEach((activeImgsetObj) => {
            show(n, activeImgsetObj.currindex, activeImgsetObj.activeImgset);
          });
        }
      }
    }

    // show the activeimg: every 1 second, will have one or more img changing
    async function show(n, currindex, activeImgset) {
      await timeout(n, currindex, activeImgset);
    }

    function timeout(n, currindex, activeImgset) {
      return new Promise((resolve) => {
        setTimeout(() => {
          let dataIndex = Number(activeImgset.getAttribute("data_index"));

          if (dataIndex === numImgs) {
            activeImgset.setAttribute("data_index", 0);
            dataIndex = Number(activeImgset.getAttribute("data_index"));
          }
          showImg(dataIndex, imgArr[currindex]);
          activeImgset.setAttribute("data_index", dataIndex + 1);

          resolve();
        }, 1000 * n);
      });
    }

    // show activeimg
    const showImg = (img_n, imgset_imgs) => {
      hideImgs(imgset_imgs);
      imgset_imgs[img_n].style.opacity = 1;
      imgset_imgs[img_n].style.transition = "opacity 1s .2s";
    };

    // all imgs as opacity 0 in an imgset
    const hideImgs = (imgNodes) => {
      for (let m = 0; m < imgNodes.length; m++) {
        imgNodes[m].style.opacity = 0;
      }
    };

    // init image collage animation right away
    imageCollage();

    // run image collage animation infinity in every 25 seconds.
    // 25 seconds is calcualted by the total spending time of seven ways to display six images in seven rounds
    let timer = setInterval(() => {
      imageCollage();
    }, 25000);

    return ()=>{
      clearInterval(timer)
    }
  }, []);

  return (
    <>
      <div className="image-collage">
        {imgData.imgs.map((imgsetObj, i) => {
          return (
            <div
              className={`appearImg imgset _img${i}`}
              data_index="0"
              curr_index={i}
              key={i}
            >
              <div className="_img_inner is-relative">
                {imgsetObj.imgset.map((img) => {
                  return (
                    <img
                      ref={(el) => imgArr[i].push(el)}
                      src={img.src}
                      alt={img.caption}
                      key={img.id}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ImgCollage;
