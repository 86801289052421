import { useEffect } from "react";
import Footer from "../../components/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./index.css";

const Privacypolicy = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.to(".bnr", {
        scrollTrigger: {
        trigger:  '.bnr',
        start: "top top", 
        pin: true, 
        pinSpacing: false
        },
      });

    });

     return () => ctx.revert();
  }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <div>
        <section className="global-padding bnr-privacy">
          <div className="heading-wrapper">
            <h2>Privacy Policy</h2>
            <p className="intro">This Privacy Policy describes the personal information that Novara Properties Ltd. and their parents, subsidiaries and affiliates (“Novara”, “us” or “we”) collects related to the www.novaraproperties.com Website (the “Website”), how we manage that information and the circumstances under which we may disclose that information. This Privacy Policy is subject to change. You should periodically check the Website for updates to this Privacy Policy. Your continued use of the Website following any changes constitutes your acceptance of these changes.</p>
            <h3>Personal Information</h3>
            <p>Personal information means information about an identifiable individual, as more particularly described under applicable Canadian privacy legislation. Personal information we collect in relation to the Website may include:<br/>
            Information, inquiries or comments you provide to us when you contact us, including name, address, email address, telephone number and other contact information; and such other information we may collect with your consent or as permitted or required by law.
            </p>
            <p>We generally use personal information we collect in relation to the Website for the following purposes:<br/>
            Replying to your requests, inquiries and comments; meeting legal requirements; and for other purposes that are disclosed at the time of collection, with your consent or as permitted or required by law. We may also use personal information obtained from you through the Website, such as contact information (name, phone number and email address), to provide you with company information, such as e-mail notifications, literature requests, and newsletter subscriptions or other information which we think might be of interest to you. You may opt out of this use of your personal information by contacting INFO@novaraproperties.ca.
            </p>
            <p>We may disclose personal information in the following circumstances:</p>
            <h3>Business Transactions</h3>
            <p>Personal information may be used by Novara and disclosed to parties connected with the proposed or actual financing, securitization, insuring, sale, assignment or other disposal of all or part of Novara or our business or assets, for the purposes of evaluating and/or performing the proposed transaction. Assignees or successors of Novara or our business or assets may use and disclose your personal information for similar purposes as those described in this Privacy Policy.</p>
            <h3>Service Providers</h3>
            <p>Novara may transfer personal information to service providers (including affiliates of Novara acting in this capacity) that perform services on our behalf, for example internal audit, management, billing, website hosting or similar services, or otherwise to collect, use, disclose, store or process personal information on our behalf for the purposes described in this Privacy Policy.</p>
            <h3>Legal and Regulatory Requirements</h3>
            <p>Novara may disclose your personal information as necessary to meet legal, regulatory, industry self-regulatory, insurance, audit, and security requirements, and as otherwise with your consent or as permitted or required by law (including as required by applicable Canadian and foreign laws applicable Novara or its agents and service providers, and including lawful requirements to disclose personal information to government authorities in those countries).</p>
            <p>We also may gather certain information about the use of our Website by our visitors. This information is then compiled and analyzed in a way that does not identify our visitors personally. The information may include the referring Uniform Resource Locator (URL), indicating from where a visitor to our Website has come, which URLs have been visited on our Website and in what order, what browser is being used, and what Internet Protocol (IP) address a visitor used to get to our Website. We may also use such information for Website and system administration.</p>
            <h3>Access and Correction</h3>
            <p>Novara may establish and maintain a file of the personal information subject to this Privacy Policy for the purposes described above. Authorized employees, agents and mandatories of Novara and our service providers who require access to personal information in order to fulfil their job requirements will have access to this file which will be kept at our offices or on our servers or those of our service providers. To the extent provided by applicable law, you may request access to personal information in our custody or control, and the correction of such information, by writing to us at INFO@novaraproperties.ca., attention: Privacy Officer. We may take reasonable steps to verify a person’s identity before granting access or making corrections.</p>
            <h3>Security</h3>
            <p>We take reasonable steps using physical, electronic or procedural security measures appropriate to the sensitivity of the information in our custody or control, which may include safeguards to protect personal information from loss, theft, misuse, and unauthorized access, use, copying, disclosure, alteration, or destruction. You should keep in mind that no Internet transmission is ever 100% secure or error-free. In particular, e-mail sent to or from this site may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>
            <h3>Links to Other Web Sites</h3>
            <p>This site may contain links or references to other Web sites. Please be aware that we do not control other Web sites and that, in any case, this Privacy Policy does not apply to those Web sites. We encourage you to read the privacy policy of every Web site you visit.</p>
            <h3>Updates</h3>
            <p>You may opt out of this use of your personal information at any time by clicking the Unsubscribe link at the bottom of our emails. To file a complaint, please contact Privacy Officer at INFO@novaraproperties.ca.</p>
          </div>
        </section>
      </div>
      <div style={{ position: "relative", zIndex: 2, overflow: "hidden" }}>
        <Footer />
      </div>
    </ReactLenis>
  );
};

export default Privacypolicy;
