import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./index.css";

const Thankyou = () => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.to(".bnr", {
        scrollTrigger: {
        trigger:  '.bnr',
        start: "top top", 
        pin: true, 
        pinSpacing: false
        },
      });

    });

     return () => ctx.revert();
  }, []);

  return (
    <ReactLenis root options={{ smoothTouch: true }}>
      <div>
        <section className="bnr-thankPage bnr global-padding">
          <div className="heading-wrapper">
            <h1>
              <div className="para-text">Thank you for</div>
              <div className="appear-text">your interest in</div>
              <div className="appear-text">Ashlyn.</div>
            </h1>
            <p>
              You’ll be among the first to learn more details by joining the
              interest list. <br />
              We will be in touch with more information soon.
            </p>
            <Link to="/" className="backHomeBtn">
              Go back to homepage
            </Link>
          </div>
        </section>
      </div>
      <div style={{ position: "relative", zIndex: 2, overflow: "hidden" }}>
        <Footer />
      </div>
    </ReactLenis>
  );
};

export default Thankyou;
