import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import { useLenis } from "@studio-freight/react-lenis";

function Header() {
  const location = useLocation();

  const [scroll, setScroll] = useState(0);
  let scrollPos = 0;

  useLenis(({ scroll }) => {
    if (scroll === 0) {
      setScroll(0);
    } else if (scroll > scrollPos) {
      setScroll(true);
    } else if (scroll < scrollPos) {
      setScroll(false);
    }
    scrollPos = scroll;
  });

  useEffect(() => {
    // if (location.pathname === "/") {
    //   let ctx = gsap.context(() => {
    //     gsap.to("header", {
    //       opacity: 1,
    //       ease: "power2.easeOut",
    //       delay: 0.5,
    //       duration: 0.3,
    //     });
    //   });
    //   return () => ctx.revert();
    // } else {
    //   let ctx = gsap.context(() => {
    //     gsap.set("header", {
    //       opacity: 1,
    //     });
    //   });
    //   return () => ctx.revert();
    // }
  }, [location.pathname]);

  return (
    <header className={scroll === 0 ? "" : scroll ? "hide" : "headerShow"}>
      <nav className="nav">
        <Link to="/">
          <img
            className="ashlyn-literal-logo"
            src="images/logo/Ashlyn_Logo_White.svg"
            alt="Ashlyn logo"
          />
        </Link>
        <img
          className="ashlyn-logo"
          src="images/logo/Ashlyn_Icon_White.svg"
          alt="Ashlyn logo"
        />
        <Link to="/#register" className="register-btn-wrapper">
          <div className="btn">Register</div>
        </Link>
      </nav>
    </header>
  );
}

export default Header;
