import React from "react";
import Select from "react-select";
import { useEffect, useState, useRef } from "react";
//refer：Invisible reCAPTCHA  https://www.npmjs.com/package/react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";

const MailchimpForm = () => {
  const recaptchaRef = useRef();
  const options = [
    {
      value: "Signage",
      label: "Signage",
    },
    { value: "Realtor", label: "Realtor" },
    { value: "Walk/Drive By", label: "Walk/Drive By" },
    { value: "REW", label: "REW" },
    { value: "Livabl", label: "Livabl" },
    { value: "Online Advertising", label: "Online Advertising" },
  ];
  const RealtorOptions = [
    { value: "I am a Realtor", label: "I am a Realtor" },
    {
      value: "I am working with a Realtor",
      label: "I am working with a Realtor",
    },
    { value: "Neither", label: "Neither" },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      // match with the menu
      borderRadius: "none",
      // Overwrittes the different states of border
      borderColor: "none",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "none",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const [formData, setFormData] = useState({
    email: null,
    tags: 7075190,
    fname: null,
    lname: null,
    phone: null,
    postalCode: null,
    hearUs: "Select",
    realtorStatus: null,
    brokerage: null,
    allowContact: "No",
  });
  const [show, setSHow] = useState("none");

  const changeHandler = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const changeSelectHandler = (event) => {
    setFormData({
      ...formData,
      hearUs: event.value,
    });
  };
  const changeSelectRealtorStatusHandler = (event) => {
    setFormData({
      ...formData,
      realtorStatus: event.value,
    });
  };
  const allowContactHandler = (event) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        allowContact: "Yes",
      });
    } else {
      setFormData({
        ...formData,
        allowContact: "No",
      });
    }
  };

  useEffect(() => {
    formData.realtorStatus === "I am a Realtor" ||
    formData.realtorStatus === "I am working with a Realtor"
      ? setSHow("block")
      : setSHow("none");
  }, [formData]);

  return (
    <section id="register" className="register-form">
      <div className="container">
        <div className="row intro">
          <div className="col-12 col-md-6">
            <h2>
              Be the first to
              <br />
              discover Ashlyn.
            </h2>
          </div>
          <div className="col-12 col-md-5">
            <p>
              We’re excited to introduce you to the collection of garden
              townhomes at Ashlyn. Please register your details below to find
              out more and a member of our Sales team will be in touch.
            </p>
          </div>
        </div>
        <form
          id="contact-form"
          className="form"
          action="https://spark.re/macdonald-platinum-marketing-ltd/ashlyn/register/website-regi-form"
          accept-charset="UTF-8"
          method="post"
          onSubmit={() => {
            recaptchaRef.current.execute();
          }}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value="5Ulm2eVUu/gSPsHGiRBkTV9WzrPnGAMiidIAbgzjO4sYy7VxVKeK8t/6Xn4vyCuFQzxquqtW4vJ0QdILHHVc5w=="
            autocomplete="off"
          />
          <div className="details">
            <div className="wrapper">
              <input
                type="text"
                id="fname"
                name="contact[first_name]"
                value={formData.fname}
                onChange={changeHandler}
                className=""
                required={true}
              />
              <br />
              <label for="fname">*FIRST NAME</label>
            </div>
            <div className="wrapper">
              <input
                type="text"
                id="lname"
                name="contact[last_name]"
                value={formData.lname}
                onChange={changeHandler}
                className=""
                required={true}
              />
              <br />
              <label for="lname">*LAST NAME</label>
            </div>
            <div className="wrapper">
              <input
                id="email"
                name="contact[email]"
                type="email"
                value={formData.email}
                onChange={changeHandler}
                className=""
                required={true}
              />
              <br />
              <label for="email">*EMAIL</label>
            </div>
            <div className="wrapper">
              <input
                type="text"
                id="phone"
                name="contact[phone]"
                value={formData.phone}
                onChange={changeHandler}
                className=""
                required={true}
              />
              <br />
              <label for="phone">*PHONE NUMBER</label>
            </div>
            <div className="wrapper">
              <input
                type="text"
                id="postalCode"
                name="contact[postcode]"
                value={formData.postalCode}
                onChange={changeHandler}
                className=""
                required={true}
              />
              <br />
              <label for="postalCode">*POSTAL CODE</label>
            </div>
            <div className="wrapper">
              <Select
                name="answers[21653][answers]"
                options={options}
                placeholder={<div className="selectPlaceholderText"></div>}
                className="registerSelect"
                onChange={changeSelectHandler}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                required={true}
              />
              <label for="HearUs">*HOW DID YOU HEAR ABOUT US?</label>
            </div>
            <div className="wrapper">
              <Select
                name="answers[21844][answers]"
                options={RealtorOptions}
                placeholder={<div className="selectPlaceholderText"></div>}
                className="registerSelect"
                onChange={changeSelectRealtorStatusHandler}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                required={true}
              />
              <label for="RealtorStatus">*REALTOR STATUS</label>
            </div>
            <div className="wrapper" style={{ display: show }}>
              <input
                type="text"
                id="brokerage"
                name="answers[21849][answers]"
                value={formData.brokerage}
                onChange={changeHandler}
                className=""
              />
              <br />
              <label for="brokerage">*Brokerage Name</label>
            </div>
            <div className="wrapper checkbox-contianer">
              <div className="checkbox-wrapper">
                <input
                  className="checkbox"
                  onChange={allowContactHandler}
                  type="checkbox"
                  name="answers[21728][answers][]"
                  value="Yes"
                />
                <div className="agreement">
                  *Yes, I consent to receiving emails from Novara Properties.
                  <br />
                  <span className="desktop-version is-small">
                    The information that you provide will be used by Novara
                    Properties and Macdonald Platinum Marketing Ltd. to enhance
                    your home ownership experience. I understand this may take
                    the form of communication via telephone, apps, sms, email
                    (including via third party providers and platforms) or post.
                    E&OE. Select members of our Sales Team are licensed Real
                    Estate Professionals and cannot provide representation to
                    potential buyers of Ashlyn.
                  </span>
                </div>
              </div>
              <div className="mobile-version is-small">
                The information that you provide will be used by Novara
                Properties and Macdonald Platinum Marketing Ltd. to enhance your
                home ownership experience. I understand this may take the form
                of communication via telephone, apps, sms, email (including via
                third party providers and platforms) or post. E&OE. Select
                members of our Sales Team are licensed Real Estate Professionals
                and cannot provide representation to potential buyers of Ashlyn.
              </div>
            </div>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdTZcspAAAAAJ4bARbP2HJbY1OiZBNZLZi-MeP0"
          />
          <button className="btn submit-btn" type="submit" value="Submit">
            Submit
          </button>
          <input
            type="hidden"
            name="source"
            id="source"
            value="Website"
            autocomplete="off"
          />
          <input
            type="hidden"
            name="redirect_success"
            id="redirect_success"
            value="https://www.ashlynliving.com/thankyou"
            autocomplete="off"
          />
          <input
            type="hidden"
            name="redirect_error"
            id="redirect_error"
            value=""
            autocomplete="off"
          />
          <input
            type="text"
            name="are_you_simulated"
            id="are_you_simulated"
            placeholder="Leave this field blank"
            style={{ display: "none" }}
          />
          <input
            type="hidden"
            name="g-recaptcha-response"
            id="g-recaptcha-response"
            className="g-recaptcha-response"
            autocomplete="off"
          />
        </form>
      </div>
    </section>
  );
};

export default MailchimpForm;
