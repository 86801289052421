import Header from "../../components/Header";
import { Outlet, ScrollRestoration } from "react-router-dom";

function Root() {

  return (
    <>
      <div className="page-wrapper">
        <Header />
        <main>
          <Outlet />
          <ScrollRestoration />
        </main>
      </div>
    </>
  );
}

export default Root;
