import { createBrowserRouter } from "react-router-dom";
import Root from "../pages/Root";
import Home from "../pages/Home";
import Thankyou from "../pages/Thankyou";
import Privacypolicy from "../pages/Privacypolicy";

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/thankyou",
        element: <Thankyou />,
      },
      {
        path: "/privacypolicy",
        element: <Privacypolicy />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;
