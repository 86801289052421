import { Link } from "react-router-dom";
const Footer = () => {
  const mapMobile = window.matchMedia("(max-width: 667px)");
  return (
    <footer className="footer global-padding">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 col-xl-4">
            <h3>Presentation Centre</h3>
            <div className="address">
              1510 W 71st Ave, Vancouver, BC
              <br />
              {/* Open Daily 12 - 5pm (closed Fridays)<br/> */}
              info@ashlynliving.com
            </div>
            <div className="social-medias">
              <img
                loading="lazy"
                src="images/social-medias/facebook-white.png"
                alt="facebook"
              />
              <img
                loading="lazy"
                src="images/social-medias/instagram-white.png"
                alt="instagram"
              />
              <img
                loading="lazy"
                src="images/social-medias/linkedin-white.png"
                alt="linkedin"
              />
            </div>
          </div>
          <div className="col-12 col-md-4 col-xl-3 right-col">
            <div className="novara-macdonald">
              <a
                href="https://www.novaraproperties.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  className="novara-logo-icon"
                  src="images/logo/Novara_Properties_logo_white.svg"
                  alt="NOVARA Logo"
                />
              </a>
              <a
                href="https://macdonaldprojects.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  className="macdonald-logo-icon"
                  src="images/logo/Macdonald_logo_white.svg"
                  alt="Macdonald Logo"
                />
              </a>
            </div>
            <div className="term">
              <Link to="/privacypolicy">Privacy Policy</Link>
              &nbsp; | &nbsp;
              <a
                href="https://www.alabcreative.com/"
                target="_blank"
                rel="noreferrer"
              >
                Site By ALab creative
              </a>
            </div>
            <div className="copyright">
              © 2024 Novara Properties. All rights reserved. This is not an
              offering for sale. Any such offering can only be made with a
              disclosure statement. Prices are subject to change without notice.
              The developer reserves the right to make changes and modifications
              to the information contained herein without prior notice. Artist’s
              renderings and maps are representations only and may not be
              accurate. E.&O.E.
            </div>
          </div>
        </div>
        {mapMobile.matches ? (
          <img
            loading="lazy"
            className="ashlyn-large-logo"
            src="images/logo/ashlyn-large-logo-m.png"
            alt="Ashlyn logo"
          />
        ) : (
          <img
            loading="lazy"
            className="ashlyn-large-logo"
            src="images/logo/ashlyn-large-logo.png"
            alt="Ashlyn logo"
          />
        )}
      </div>
    </footer>
  );
};

export default Footer;
